require('../js/fancybox/jquery.fancybox.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('../js/rgpd-simple/rgpd-simple.css');
require('../css/app.scss');

// Images
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp|pdf)$/);
imagesContext.keys().forEach(imagesContext);

// PDFS
const pdfsContext = require.context('../pdfs', true, /\.(pdf)$/);
pdfsContext.keys().forEach(pdfsContext);

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('jquery-ui');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-fr');
// require('datatables.net-bs4');

/***************************
 * ROUTING JS
 * ex :
 * router.routing.generate('monurl')
 * router.routing.generate('monurl2', {'param1': 'value1', 'params2': 'value2})
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Les routes routes doivent avoir l'option expose true, ex :
 *
 backoffice/ajax-sort:
 path: backoffice/ajax-sort
 controller: App\Controller\Backoffice\BackofficeController:ajaxSort
 options:
 expose: true

 Après la création d'une nouvelle route vous devez également lancer la commande suivante pour récupérer les routes dans le JS

 php bin/console fos:js-routing:dump --format=json --target=public/js/fos_js_routes.json

 puis compiler les assets

 yarn encore dev
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

// import le fichier router dans ce fichier
const router = require('./router.js');
// permet son utilisation dans les fichiers twig
global.router = router;

$(document).ready(function ($) {

    if (window.scrollY === 0) {
        $("#menu-site").removeClass("menu-scroll");
    } else {
        $("#menu-site").addClass("menu-scroll");
    }
    // body page header


    // BOUTTON TO TOP
    $(window).scroll(function () {
        var scroll = $(document).scrollTop();

        if (scroll > 2) {
            $("#menu-site").addClass("menu-scroll");

        } else {
            $("#menu-site").removeClass("menu-scroll");

        }
    });

    //
    // /*****************************************************
    //  * FOOTER HEIGHT
    //  *
    //  */
    //
    // let footerHeight = $("footer .content").outerHeight();
    // $('footer').css('height', footerHeight+'px');


    /***************************
     * LANGUAGE SWITCHER
     */
    $(document).on({
        change: function () {
            var thisElt = $(this);
            $.ajax({
                type: "POST",
                url: router.routing.generate('ajax/set-locale'),
                dataType: 'json',
                data: {
                    language: thisElt.val()
                },
                success: function (msg) {
                    if (!msg.success) {
                        showAlert({
                            class: 'alert-danger',
                            message: 'Une erreur est survenue'
                        });
                    } else {
                        if (thisElt.find('option:selected').attr('data-url')) {
                            location.href = thisElt.find('option:selected').attr('data-url');
                        } else {
                            document.location.reload();
                        }
                    }
                }
            });
        }
    }, 'select[name="language-switcher"]');

    /***************************
     * UNVEIL
     */
    $('.unveil').unveil();

    /***************************
     * ECOUTEUR ALERTS
     */
    $(document).on({
        click: function () {
            $(this).remove();
        }
    }, '.alert');

    window.setTimeout(fadeMyDiv(), 3000);

    function fadeMyDiv() {
        $(".alert-fixed").fadeOut(3000);
    }

    /***************************
     * MENU
     */
    var mobileViewports = ['xs', 'sm', 'md'];
    if (mobileViewports.indexOf(getBootstrapViewport()) >= 0) {
        $('#menu-site').hcOffcanvasNav({
            maxWidth: false,
            levelOpen: 'expand',
            labelClose: 'Fermer',
            labelBack: 'Retour',
            levelSpacing: 10,
            position: 'right'
        });
    }

    /***************************
     * ECOUTEUR PASSWORD SEEABLE
     */
    $(document).on({
        click: function () {
            $(this).parent().find('input[type="password"]').prop('type', 'text');
            return false;
        }
    }, '.password-seeable i');

    /***************************
     * ECOUTEUR DATEPICKER
     */
    $('.birthdaypicker-widget').datepicker({
            buttonText: "Choose",
            dateFormat: 'd/m/yy',
            changeMonth: true,
            changeYear: true,
            yearRange: "-120y:-18y",
            defaultDate: "-30y",
            onSelect: function (dateText, inst) {
                var dates = dateText.split('/');
                var parent = inst.dpDiv.parents('.datepicker');

                var day = dates[0];
                var month = dates[1];
                var year = dates[2];

                $("select[name*='day']", parent).val(day);
                $("select[name*='month']", parent).val(month);
                $("select[name*='year']", parent).val(year);

                $(this).toggleClass('visible');
            },
        },
        $.datepicker.regional["fr"]
    );


    $(document).on({
        click: function () {
            $(this).parents('.datepicker').find('.datepicker-widget').toggleClass('visible')
            $(this).parents('.datepicker').find('.birthdaypicker-widget').toggleClass('visible')
        }
    }, '.datepicker-widget-wrapper i');


    /************************
     * Page  actualité
     */

    $(document).on({
        click: function () {
            // modif couleur au filtre
            let thisTable = $('#actualities_filter');
            console.log(thisTable);
            if ($(this).hasClass('background-orange')) {
                // $(this).removeClass('background-orange');
                location.reload()
            } else {
                $('.actuality ', thisTable).each(function () {
                    $(this).removeClass('background-orange');
                });
                $(this).addClass('background-orange');
                // return result form filter
                let thisLet = $(this);
                $.ajax({
                    type: "POST",
                    url: router.routing.generate('ajax/filtre-actualities'),
                    data: 'tagFilter=' + thisLet.data('filter'),
                    dataType: 'json',
                    success: function (msg) {
                        var actualityView = '';
                        for (var i = 0; i < msg.actualities.length; i++) {
                            actualityView += 'titre actualité ' + msg.actualities[i]['id'] + '<br>'
                            ;
                        }
                        $('#actualities_active').html(msg.returnHtml);
                    }
                });
            }

        }
    }, '.actuality_filter');


    /***************************
     * ECOUTEUR FANCYBOX
     */
    if ($().fancybox) {
        // Fermeture fancybox
        $(document).on({
            click: function () {
                $.fancybox.close();
                return false;
            }
        }, '.btn-close-fancybox-custom');
    }

    /***************************
     * ECOUTEUR cards expand
     */
    if ($('.cards').length) {
        // Fermeture fancybox
        $(document).on({
            click: function () {
                $(this).find('.right').toggleClass('active')
            }
        }, '.card');
    }
});

/***************************
 * Dans quelle vue de bootstrap on est
 */
var viewport_xs_max = 576;
var viewport_sm_max = 768;
var viewport_md_max = 992;
var viewport_lg_max = 1200;

global.getBootstrapViewport = function () {
    if ($(window).width() < viewport_xs_max) {
        return 'xs';
    } else if ($(window).width() >= viewport_xs_max && $(window).width() < viewport_sm_max) {
        return 'sm';
    } else if ($(window).width() >= viewport_sm_max && $(window).width() < viewport_md_max) {
        return 'md';
    } else if ($(window).width() >= viewport_md_max && $(window).width() < viewport_lg_max) {
        return 'lg';
    } else {
        return 'xl';
    }
}

/***************************
 * AFFICHER ALERTE
 */
global.showAlert = function (params) {
    var alert = $('<div class="alert-fixed"><div class="alert ' + params.class + '"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + params.message + '</div></div>').prependTo('body');
    setTimeout(function () {
        removeAlert({
            element: alert
        });
    }, 3000);
}

/***************************
 * CACHER ALERTE
 */
global.removeAlert = function (params) {
    if (typeof $(params.element) != 'undefined' && $(params.element).length) {
        $(params.element).fadeOut('slow', function () {
            $(params.element).remove();
        })
    }
}


var rellax = new Rellax('.desktop .rellax', {});

$(document).ready(function () {
    $('#partners').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    });
});